import React, { useEffect, useState, useRef } from 'react';
import { StyleSheet, View, Text, ScrollView, Image, TouchableOpacity, Platform, Linking, Dimensions } from 'react-native';
import { commonStyle } from '../components/style';
import { SimpleLineIcons, Fontisto, AntDesign, Entypo, MaterialIcons, Feather, FontAwesome5, EvilIcons,FontAwesome,Ionicons  } from '@expo/vector-icons';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { useTranslation } from 'react-i18next'
import { userCenter, getWebsiteInfo, newmessage, checkbankcard, getPromotionTest, getWithdraw, certification,getUserbalance } from '../api/apis';
import AsyncStorage from '@react-native-community/async-storage';
import SpliteLine from '../components/spliteLine'
import { use } from 'i18next';
import appJson from '../../app.json'; // 请根据 app.json 文件的路径进行相应的更改
import Constants from 'expo-constants';
import * as Updates from 'expo-updates';
import { commonLang } from '../components/config';
import Toast, { DURATION } from 'react-native-easy-toast'
import { commonConfig } from '../components/config';
import DraggableButton from '../components/draggableButton';
import { vipGrade } from '../components/util';
import ProgressBar from 'react-native-progress-bar-animated';
import { showAlert } from '../components/showAlert';

const My = () => {

    if (Dimensions.get('window').width > 500) {
        Dimensions.get('window').width = 500
    }
    const { width } = Dimensions.get('window');
    const { i18n, t } = useTranslation();
    const toastRef = useRef(null);
    const [userInfo, setUserInfo] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const [unreadMessage, setUnreadMessage] = useState(0); //未读消息
    const [depositsWithdrawals, setDepositsWithdrawals] = useState([0, 0]); //1_1 提现和充值显示
    const [domain, setDomain] = useState('');
    const [appDown, setAppDown] = useState('');
    const [vip, setVip] = useState('');  // vip等级
    const [kefu, setKefu] = useState('');
    const [userCreditscore, setUserCreditscore] = useState('');
    const [balance, setBalance] = useState('');
    const [currency, setCurrency] = useState('USDT');
    const [user_verification_level, setUser_verification_level] = useState('');
    const [agency, setAgency] = useState(0);  // 否显示代理中心  0关闭1开启

    const languages = commonLang.lang;

    useEffect(() => {
        init()
    }, []);

    useFocusEffect(
        React.useCallback(() => {
            // console.log('my在页面获得焦点时执行')
            isLoggedIn()
            getCertification()
            getUserbalance().then(response => {
                if (response.status == 1 && response.Data.Code == '1') {
                    setBalance(response.Data.BackData);
                }
            }).catch(error => {
                // console.error(error);
            })
        }, [])
    );

    const isLoggedIn = async () => {
        const userinfo = await AsyncStorage.getItem('userinfo');
        const cookies = await AsyncStorage.getItem('cookies');
        if (userinfo) {
            setUserInfo(JSON.parse(userinfo))
            setIsLogin(true)
        } else {
            setUserInfo(false)
            setIsLogin(false)
            // navigation.navigate('Login');
        }

        // website  deposits_withdrawals  1_1 提现和充值显示
        const websiteInfo = await getWebsiteInfo();
        if (websiteInfo.status == '1') {
            setDepositsWithdrawals(websiteInfo.Data.deposits_withdrawals.split('_'))

        }
    }

    const init = async () => {
        // const version = appJson.expo.version;
        // console.log('当前版本', version);

        const websiteInfo = await getWebsiteInfo();
        if (websiteInfo.status == '1') {
            setDomain(websiteInfo.Data.default_domain)
            setAppDown(websiteInfo.Data.alAndroid)
            setKefu(websiteInfo.Data.sl)
            setAgency(websiteInfo.Data.Agency)

        }

        userCenter().then(response => {
            if (response.status == 1) {
                const data = response.Data1.item[0]
                setUnreadMessage(data.status)
            }
        }).catch(error => {
            // console.error(error);
        });

        getPromotionTest().then(response => {
            if (response.status == '1') {
                const v = vipGrade(response.Data1.item[0].user_integral)
                setVip(v.inte)
            }
        }).catch(error => {
            // console.error(error);
        });
    };

    const getCertification = async () => {

        // 是否上传，审核过
        const formdata = {
            // user_holdingIDcard: selectedImage,
            type: "show"
        }
        certification(formdata).then(response => {
            if (response.status == 1) {
                const itemData = response.Data
                // itemData.user_creditscore //信誉分
                // itemData.user_IDnumber //身份证号
                // itemData.user_IDphotoone //身份证正面
                // itemData.user_IDphototwo //身份证反面
                // itemData.user_holdingIDcard // 手持身份证
                // itemData.user_verification_level // 认证等级 user_verification_level 这是3位数手持身份证是百位1是已经上传还没审核通过2是审核没有通过 3是审核通过  十位 反面认证1是已经上传还没审核通过2是审核没有通过 3是审核通过      个位数是正面认证1是已经上传还没审核通过2是审核没有通过 3是审核通过 

                setUserCreditscore(itemData.user_creditscore)
                setUser_verification_level(itemData.user_verification_level)
            }
        }).catch(error => {
            console.log(error)
            // showAlert(t('fail'));
            // console.error(error);
        });

    }


    const navigation = useNavigation();

    const goToLanguageScreen = () => {
        navigation.navigate('Language');
    }
    // 分单位转换 越南盾不显示分单位
    const unitconversion = (d, curr = '') => {
        if (!(typeof (d) == 'string')) {
            d = JSON.stringify(d);
        }
        d = d.replace(/\s+/g, "");

        let f = '',
            c = '';
        if (d.substr(0, 1) == "-") {
            c = "-";
            d = d.substr(1);
        }
        // console.log();
        if (d == null || d == 'null' || d == '') {
            f = '0.00';
        } else {
            if (d.length < 5) {
                f = (+d / 100).toFixed(2);
            } else {
                if (curr == "VND") {
                    d = parseInt(d) + "";
                }
                let arr = [];
                arr[0] = d.slice(0, -2);
                arr[1] = d.slice(d.length - 2, d.length);
                f = arr.join('.')
            }
        }
        if (f.indexOf('..') != '-1') {
            f = f.replace('..', '.');
        }

        let a = f.split(".");
        let str = a[0];
        let spstr = str.split("").reverse();
        let q = 0,
            v = "";

        spstr.forEach(function (item) {
            if (q == 3) {
                v = item + "," + v;
                q = 0;
            } else {
                v = item + "" + v;;
            }
            q = q + 1;
        })
        f = v + "." + a[1];
        // console.log('f');
        // console.log(f);
        if (curr == "VND") {
            f = f.replace(/[.]/g, "-");
            f = f.replace(/[,]/g, "+");
            f = f.replace(/[-]/g, ",");
            f = f.replace(/[+]/g, ".");

            f = f.substring(0, f.length - 3);
        }
        // console.log('f');
        // console.log(f);
        return c + "" + f;
    }
    const goToCustomerScreen = async () => {

        const response = await getWebsiteInfo();
        if (response.status == '1') {
            // response.Data.sl = 'https://t.me/example_use'
            var regex = /^https?:\/\/t\.me\/[a-zA-Z0-9_]+$/;
            if (regex.test(response.Data.sl)) {
                if (Platform.OS == 'web') {
                    window.location.href = response.Data.sl
                } else {
                    Linking.openURL(response.Data.sl)
                }
            } else {
                navigation.navigate('Customer');
            }
        }

        // navigation.navigate('Customer');
    }

    const goToLoginScreen = () => {
        navigation.navigate('Login');
    }
    const goToScreen = async (route) => {
        if (!isLogin) {
            goToLoginScreen()
            return
        }

        //初级认证
        if (route == 'PrimaryAuthentication') {
            if (user_verification_level == '33' || user_verification_level.length == 3) {
                return
            }
        }

        // 高级认证
        if (route == 'AdvancedAuthentication') {
            if (user_verification_level == '333') {
                return
            }
            if (user_verification_level != '33' && user_verification_level.length == 2) {
                return
            }
        }


        //提现
        if (route == 'ExtractCurrency') {

            const res = await getUserbalance({ type: 'loanamount' });
            const loanAmount = Number(res?.Data?.other?.loanamount || 0);
            // const loanAmount = 1

            // 'repayment'  //是否支持余额还款 1支持 2是不支持
            const repayment = Number(res?.Data?.repayment || 0);
            // const repayment = 2;

            if (loanAmount > 0) {
                showAlert(t(t('tips7') + `${loanAmount/100}usdt！`), () => {
                    if(repayment == 1){
                        navigation.navigate('Repayment');
                        return
                    }
                    if(repayment == 2){
                        navigation.navigate('RechargeChannel',{repayment:true});
                        return
                    }
                },t('confirm'),t('cancel'));
                return
            } 
    
            await getWithdraw().then(async response => {
                console.log(response)
                if (response.status == 0) {
                    if (response.Data.Code == 1) {
                        //跳转初级认证
                        navigation.navigate('PrimaryAuthentication');
                        return
                    }
                    if (response.Data.Code == 2) {
                        //跳转高级认证
                        navigation.navigate('AdvancedAuthentication');
                        return
                    }
                }
                if (response.status == 1) {
                    await checkbankcard().then(response => {
                        if (response.status == 1) {
                            if (response.Data1.item.length == 0) {
                                navigation.navigate('PaymentMethod');
                                return
                            } else {
                                navigation.navigate(route);
                            }
                        }
                    }).catch(error => {
                        // console.error(error);
                    });
                }

                if (response.status != 1) {
                    toastRef.current.show(response.Data.msg);
                }

            }).catch(error => {
                // console.error(error);
            });
            return;
        }

        navigation.navigate(route);
    }

    async function onFetchUpdateAsync() {
        if (Platform.OS === 'web') {
            return;
        }

        const response = await newmessage();
        if (response.status == '1') {
            const data = response.Data
            data.Androidrefresh
            data.IOSrefresh
            data.MinAndroidrefresh
            data.MinIOSrefresh

            if (Platform.OS === 'ios' && appJson.expo.version >= data.MinIOSrefresh) {
                toastRef.current.show(t('noUpdates'));
                return;
            }
            if (Platform.OS === 'android' && appJson.expo.version >= data.MinAndroidrefresh) {
                toastRef.current.show(t('noUpdates'));
                return;
            }
            if (appDown != '') {
                Linking.openURL(appDown);
            }

        }



        // try {
        //     const update = await Updates.checkForUpdateAsync();
        //     if (update.isAvailable) {
        //         alert(t('waitingForUpdates'))
        //         await Updates.fetchUpdateAsync();
        //         await Updates.reloadAsync();
        //     } else {
        //         // await Updates.fetchUpdateAsync();
        //         alert(t('noUpdates'))
        //     }
        // } catch (error) {
        //     // You can also add an alert() to see the error message in case of an error when fetching updates.
        //     alert(`${error}`);
        //     console.log(`${error}`);
        // }
    }

    return (
        <View style={styles.container}>
            <DraggableButton />
            <ScrollView
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
                style={{ width: '90%' }}
            // style={{ marginHorizontal:10  }}

            >
                <View style={[styles.section, { marginTop: 25, }]}>
                    <Text style={styles.title}>{t('me')}</Text>
                    <TouchableOpacity style={styles.iconBadge} onPress={() => { goToScreen('MessageNotifications') }}>
                        <SimpleLineIcons name="bell" size={22} color="#FFF" />
                        {/* {unreadMessage > 0 && <View style={{ width: 14, height: 14, backgroundColor: '#e54142', borderRadius: 10, position: 'relative', top: -28 }}>
                            <Text style={{ color: '#fff', textAlign: 'center', fontSize: 10 }}>{unreadMessage > 99 ? 99 : unreadMessage}</Text>
                        </View>} */}
                    </TouchableOpacity>
                </View>
                <View style={styles.sectionContainer}>
                    <View style={[styles.section, { width: '100%', margin: 'auto', marginBottom: 15 }]}>
                        {userInfo ? (
                            <View style={{ flexDirection: 'row' }}><Text style={styles.name}>{userInfo.user_name ? userInfo.user_name : userInfo.user_account_number}</Text>
                                <Text style={{ color: '#fff', marginLeft: 10, lineHeight: 32 }}>{vip}</Text></View>
                        ) : (
                            <TouchableOpacity onPress={goToLoginScreen}>
                                <Text style={[styles.name, { marginLeft: 15 }]}>{t('login')}</Text>
                            </TouchableOpacity>
                        )}
                        {userInfo && <View style={[styles.switchSection,{ flexDirection: 'column' }]}>
                            {/* <Text style={styles.country}> {languages[i18n.language]}【{i18n.language.toUpperCase()}】</Text>
                            <View style={styles.switch} >
                                <TouchableOpacity style={styles.icon} onPress={goToLanguageScreen}>
                                    <AntDesign name="swap" size={20} color="#00FF9B" />
                                </TouchableOpacity>
                            </View> */}
                            <Text style={[styles.totalAssetsText, { textAlign: 'right' }]}>{t('totalAssets')}</Text>
                            <Text style={[styles.totalAssetsText, { textAlign: 'right' }]}>{unitconversion(balance)}</Text>
                        </View> }

                    </View>
                    <View style={[styles.section]}>
                        {userInfo &&
                            <View>
                                <View>
                                    <Text style={{ color: '#fff', marginTop: -20, marginBottom: 10 }}>{userCreditscore && t('creditScore') + ': '}{userCreditscore}</Text>
                                </View>
                                <View style={{ width: width * 0.9 - 60, height: 10, backgroundColor: '#fff', borderRadius: 5 }}></View>
                                <View style={{ width: '100%', flexDirection: 'row' }}>
                                    <View style={{ marginTop: -10, marginBottom: 15 }}>
                                        <ProgressBar
                                            width={width * 0.9 - 60}
                                            height={10}
                                            borderRadius={5}
                                            borderWidth={0}
                                            maxValue={100}
                                            value={userCreditscore > 100 ? 100 : Number(userCreditscore)}
                                            color="#6CC644"
                                            backgroundColorOnComplete="#6CC644"
                                            backgroundColor={commonStyle.green} 
                                            useNativeDriver={true}
                                            />

                                    </View>
                                    <View style={{ marginTop: -15, marginLeft: 10 }}><Text style={{ color: '#fff' }}>{userCreditscore}</Text></View>
                                </View>

                            </View>
                        }
                    </View>
                    <View style={[styles.section, {}]}>
                        <View style={[styles.iconText, styles.flexColumn]}>
                            <TouchableOpacity style={styles.icon} onPress={() => { goToScreen('Wallet') }}>
                                <Image style={styles.image} source={require('../../assets/imgs/my/billfold.png')} />
                            </TouchableOpacity>
                            <Text style={styles.labelTop}>{t('wallet')}</Text>
                        </View>

                        {/* 充值、提现调用api参数是否显示 */}
                        {depositsWithdrawals[1] == 1 && <View style={[styles.iconText, styles.flexColumn]}>
                            <TouchableOpacity style={styles.icon} onPress={() => { goToScreen('RechargeChannel') }}>
                                <Image style={styles.image} source={require('../../assets/imgs/my/recharge.png')} />
                            </TouchableOpacity>
                            <Text style={styles.labelTop}>{t('recharge')}</Text>
                        </View>}
                        {depositsWithdrawals[0] == 1 &&
                            <View style={[styles.iconText, styles.flexColumn]}>
                                <TouchableOpacity style={styles.icon} onPress={() => { goToScreen('ExtractCurrency') }}>
                                    <Image style={styles.image} source={require('../../assets/imgs/my/withdrawal.png')} />
                                </TouchableOpacity>
                                <Text style={styles.labelTop}>{t('withdrawal')}</Text>
                            </View>
                        }

                        <View style={[styles.iconText, styles.flexColumn]}>
                            {Platform.OS == 'web1' &&
                                <a href={kefu} target='_blank' style={{ textDecoration: 'none' }}>
                                    <Image style={styles.image} source={require('../../assets/imgs/my/customer.png')} />
                                    <Text style={styles.labelTop}>{t('customer')}</Text>
                                </a>}
                            <TouchableOpacity style={styles.icon} onPress={goToCustomerScreen}>
                                <Image style={styles.image} source={require('../../assets/imgs/my/customer.png')} />
                                <Text style={styles.labelTop}>{t('customer')}</Text>
                            </TouchableOpacity>

                        </View>
                    </View>
                </View>

                
                <View style={[styles.sectionContainer, { paddingVertical: 10 }]}>


              
                    <TouchableOpacity onPress={() => { goToScreen('Loan'); }}>
                        <View style={[styles.section, , { marginVertical: 4 }]}>
                            <View style={[styles.iconText, { marginLeft: 9 }]}>
                                <View style={styles.icon}>
                                    <FontAwesome5 name="money-check-alt" size={17} color={commonStyle.textGreyColor} />
                                </View>
                                <Text style={[styles.label, { marginLeft: 6 }]}>{t('loan')}</Text>
                            </View>
                            <View style={styles.iconText}>

                                <View style={styles.goArrow}><Entypo name="chevron-thin-right" size={15} color={commonStyle.textGreyColor} /></View>
                            </View>
                        </View>
                    </TouchableOpacity> 

                    <SpliteLine style={{ marginTop: 5, marginBottom: 5 }} />
                    <TouchableOpacity onPress={() => { goToScreen('QuickExchange'); }}>
                        <View style={[styles.section, , { marginVertical: 4 }]}>
                            <View style={[styles.iconText, { marginLeft: 9 }]}>
                                <View style={styles.icon}>
                                    <FontAwesome name="exchange" size={18} color={commonStyle.textGreyColor} />
                                </View>
                                <Text style={[styles.label, { marginLeft: 8 }]}>{t('quickExchange')}</Text>
                            </View>
                            <View style={styles.iconText}>

                                <View style={styles.goArrow}><Entypo name="chevron-thin-right" size={15} color={commonStyle.textGreyColor} /></View>
                            </View>
                        </View>
                    </TouchableOpacity>
                    <SpliteLine style={{ marginTop: 5, marginBottom: 5 }} />

                    {/* 初级认证 */}
                    <TouchableOpacity style={{ display: user_verification_level == '' ? 'none' : 'block' }} onPress={() => { goToScreen('PrimaryAuthentication'); }}>
                        <View style={styles.section}>
                            <View style={styles.iconText}>
                                <View style={styles.icon}>
                                    <Fontisto name="preview" size={22} color={commonStyle.textGreyColor} style={{ marginHorizontal: 7, marginVertical: 5 }} />
                                </View>
                                <Text style={styles.label}>{t('primaryAuthentication')}</Text>
                            </View>
                            <View style={styles.iconText}>
                                {/* <Text style={styles.subText}>{t('successfulSharing')} 10BTC</Text> */}
                                <View style={styles.goArrow}>

                                    {(user_verification_level == '33' || user_verification_level.length == 3) ? <AntDesign name="checkcircle" size={15} color={commonStyle.green} /> : <Entypo name="chevron-thin-right" size={15} color={commonStyle.textGreyColor} />}

                                </View>
                            </View>
                        </View>
                        <SpliteLine style={{ marginTop: 5, marginBottom: 5 }} />
                    </TouchableOpacity>
                    {/* 高级认证 */}

                    <TouchableOpacity style={{ display: (user_verification_level == '33' || user_verification_level.length == 3) ? 'block' : 'none' }} onPress={() => { goToScreen('AdvancedAuthentication'); }}>
                        <View style={styles.section}>
                            <View style={styles.iconText}>
                                <View style={styles.icon}>
                                    <Fontisto name="preview" size={22} color={commonStyle.textGreyColor} style={{ marginHorizontal: 7, marginVertical: 5 }} />
                                </View>
                                <Text style={styles.label}>{t('advancedAuthentication')}</Text>
                            </View>
                            <View style={styles.iconText}>
                                {/* <Text style={styles.subText}>{t('successfulSharing')} 10BTC</Text> */}
                                <View style={styles.goArrow}>

                                    {user_verification_level == '333' ? <AntDesign name="checkcircle" size={15} color={commonStyle.green} /> : <Entypo name="chevron-thin-right" size={15} color={commonStyle.textGreyColor} />}

                                </View>
                            </View>
                        </View>
                        <SpliteLine style={{ marginTop: 5, marginBottom: 5 }} />
                    </TouchableOpacity>


                    {/* <TouchableOpacity onPress={() => { goToScreen('Publicize'); }}>
                        <View style={styles.section}>
                            <View style={styles.iconText}>
                                <View style={styles.icon}>
                                    <Image style={styles.image2} source={require('../../assets/imgs/my/share.png')} />
                                </View>
                                <Text style={styles.label}>{t('shareEarn')}</Text>
                            </View>
                            <View style={styles.iconText}>
                      
                                <View style={styles.goArrow}><Entypo name="chevron-thin-right" size={15} color={commonStyle.textGreyColor} /></View>
                            </View>
                        </View>
                        <SpliteLine style={{ marginTop: 5, marginBottom: 5 }} />
                    </TouchableOpacity> */}
                    <TouchableOpacity onPress={() => { goToScreen('PaymentMethod'); }}>
                        <View style={[styles.section, {}]}>
                            <View style={styles.iconText}>
                                <View style={styles.icon}>
                                    <Image style={styles.image2} source={require('../../assets/imgs/my/pay.png')} />
                                </View>
                                <Text style={styles.label}>{t('paymentMethod')}</Text>
                            </View>
                            <View style={styles.iconText}>
                                {/* <Text style={styles.subText}>{t('bankCard')}</Text> */}
                                <View style={styles.goArrow}><Entypo name="chevron-thin-right" size={15} color={commonStyle.textGreyColor} /></View>
                            </View>
                        </View>
                        <SpliteLine style={{ marginTop: 5, marginBottom: 5 }} />
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => { goToScreen('FinancialPassword'); }}>
                        <View style={styles.section}>
                            <View style={styles.iconText}>
                                <View style={styles.icon}>
                                    <Image style={styles.image2} source={require('../../assets/imgs/my/fund_password.png')} />
                                </View>
                                <Text style={styles.label}>{t('financialPassword')}</Text>
                            </View>
                            <View style={styles.iconText}>
                                {/* <Text style={styles.subText}>{t('notSet')}</Text> */}
                                <View style={styles.goArrow}><Entypo name="chevron-thin-right" size={15} color={commonStyle.textGreyColor} /></View>
                            </View>
                        </View>
                    </TouchableOpacity>
                    {/* <SpliteLine style={{ marginTop: 5, marginBottom: 5 }} />
                    <TouchableOpacity onPress={() => { goToScreen('BetRecord'); }}>
                        <View style={[styles.section, , { marginVertical: 4 }]}>
                            <View style={[styles.iconText, { marginLeft: 6 }]}>
                                <View style={styles.icon}>
                                    <Feather name="list" size={24} color={commonStyle.textGreyColor} />
                                </View>
                                <Text style={[styles.label, { marginLeft: 6 }]}>{t('betting') + t('record')}</Text>
                            </View>
                            <View style={styles.iconText}>
                       
                                <View style={styles.goArrow}><Entypo name="chevron-thin-right" size={15} color={commonStyle.textGreyColor} /></View>
                            </View>
                        </View>
                    </TouchableOpacity> */}
                    <SpliteLine style={{ marginTop: 5, marginBottom: 5 }} />
                    <TouchableOpacity onPress={() => { goToScreen('BillingRecord'); }}>
                        <View style={[styles.section, , { marginVertical: 4 }]}>
                            <View style={[styles.iconText, { marginLeft: 9 }]}>
                                <View style={styles.icon}>
                                    <FontAwesome5 name="money-bill-alt" size={17} color={commonStyle.textGreyColor} />
                                </View>
                                <Text style={[styles.label, { marginLeft: 6 }]}>{t('bill')}</Text>
                            </View>
                            <View style={styles.iconText}>
                                {/* <Text style={styles.subText}>{t('notSet')}</Text> */}
                                <View style={styles.goArrow}><Entypo name="chevron-thin-right" size={15} color={commonStyle.textGreyColor} /></View>
                            </View>
                        </View>
                    </TouchableOpacity>

                    {/* {agency == 1 && 
                    <TouchableOpacity onPress={() => { goToScreen('Publicize'); }}>
                        <SpliteLine style={{ marginTop: 10, marginBottom: 10 }} />
                        <View style={styles.section}>
                            <View style={[styles.iconText, { marginLeft: 9 }]}>
                                <View style={styles.icon}>
                                    <Ionicons name="people" size={20} color={commonStyle.textGreyColor} />
                                </View>
                                <Text style={[styles.label, { marginLeft: 7 }]}>{t('publicize')}</Text>
                            </View>
                            <View style={styles.iconText}>
                                <View style={styles.goArrow}><Entypo name="chevron-thin-right" size={15} color={commonStyle.textGreyColor} /></View>
                            </View>
                        </View>
                    </TouchableOpacity>
                    } */}


                    <TouchableOpacity onPress={() => { goToScreen('About'); }}>
                        <SpliteLine style={{ marginTop: 10, marginBottom: 10 }} />
                        <View style={styles.section}>
                            <View style={styles.iconText}>
                                <View style={styles.icon}>
                                    <EvilIcons name="user" size={27} color={commonStyle.textGreyColor} style={{ marginHorizontal: 6, marginTop: -2 }} />
                                </View>
                                <Text style={[styles.label, { marginLeft: -2 }]}>{t('aboutUs')}</Text>
                            </View>
                            <View style={styles.iconText}>
                                <View style={styles.goArrow}><Entypo name="chevron-thin-right" size={15} color={commonStyle.textGreyColor} /></View>
                            </View>
                        </View>
                    </TouchableOpacity> 


                </View>
                <View style={styles.sectionContainer2}>

                    <TouchableOpacity onPress={() => { goToScreen('Settings'); }}>
                        <View style={styles.section}>
                            <View style={styles.iconText}>
                                <View style={styles.icon}>
                                    <Fontisto name="player-settings" size={21} color={commonStyle.textGreyColor} style={{ marginHorizontal: 6 }} />
                                </View>
                                <Text style={styles.label}>{t('settings')}</Text>
                            </View>
                            <View style={styles.iconText}>
                                <View style={styles.goArrow}><Entypo name="chevron-thin-right" size={15} color={commonStyle.textGreyColor} /></View>
                            </View>
                        </View>
                    </TouchableOpacity>

                    {Platform.OS !== 'web' && <TouchableOpacity onPress={onFetchUpdateAsync}>
                        <SpliteLine style={{ marginTop: 10, marginBottom: 10 }} />
                        <View style={styles.section}>
                            <View style={styles.iconText}>
                                <View style={styles.icon}>
                                    <MaterialIcons name="system-update" size={22} color={commonStyle.textGreyColor} style={{ marginHorizontal: 6 }} />
                                </View>
                                <Text style={styles.label}>{t('checkForUpdates')}</Text>
                            </View>
                            <View style={styles.iconText}>
                                <View style={styles.goArrow}><Entypo name="chevron-thin-right" size={15} color={commonStyle.textGreyColor} /></View>
                            </View>
                        </View>
                    </TouchableOpacity>}

                    {/* <TouchableOpacity onPress={() => { navigation.navigate('ArticleDetail'); }}>
                        <View style={styles.section}>
                            <View style={styles.iconText}>
                                <View style={styles.icon}>
                                    <Entypo name="help-with-circle" size={20} color={commonStyle.textGreyColor} style={{ marginRight: 5 }} />
                                </View>
                                <Text style={styles.label}>{t('helpCenter')}</Text>
                            </View>
                            <View style={styles.iconText}>
                                <View style={styles.goArrow}><Entypo name="chevron-thin-right" size={15} color={commonStyle.textGreyColor} /></View>
                            </View>
                        </View>
                    </TouchableOpacity>

                    <TouchableOpacity onPress={() => { navigation.navigate('ArticleDetail'); }}>
                        <View style={styles.section}>
                            <View style={styles.iconText}>
                                <View style={styles.icon}>
                                    <AntDesign name="message1" size={20} color={commonStyle.textGreyColor} style={{ marginRight: 5 }} />
                                </View>
                                <Text style={styles.label}>{t('contactUs')}</Text>
                            </View>
                            <View style={styles.iconText}>
                                <View style={styles.goArrow}><Entypo name="chevron-thin-right" size={15} color={commonStyle.textGreyColor} /></View>
                            </View>
                        </View>
                    </TouchableOpacity> */}

                </View>
                {Platform.OS !== 'web' &&
                    <Text style={{ textAlign: 'center', color: '#404040', marginTop: 10 }}>Version {appJson.expo.version}</Text>
                }
            </ScrollView>
            <Toast
                ref={toastRef}
                style={{ backgroundColor: 'gray' }}
                position='top'
                positionValue={200}
                textStyle={{ color: '#fff' }} />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: commonStyle.bgColor,
        alignItems: 'center',
        justifyContent: 'center',
        margin: 0,
    },
    sectionContainer: {
        backgroundColor: '#222430',
        marginBottom: 20,
        borderRadius: 5,
        paddingHorizontal: 10,
        paddingVertical: 20,
    },
    sectionContainer2: {
        backgroundColor: '#222430',
        // marginBottom: 20,
        borderRadius: 5,
        paddingHorizontal: 10,
        paddingVertical: 15,
    },
    section: {
        // marginBottom: 15,
        flexDirection: "row",
        justifyContent: 'space-between',
        // marginVertical: 2
    },
    title: {
        fontSize: 18,
        // fontWeight: 'bold',
        marginBottom: 10,
        color: commonStyle.textWhiteColor
    },
    iconBadge: {
        fontSize: 16,
        // fontWeight: 'bold',
    },
    totalAssetsText: {
        color: '#fff',
    },
    name: {
        fontSize: 20,
        // fontWeight: 'bold',
        marginBottom: 10,
        color: commonStyle.textWhiteColor,
        // maxWidth:'50%'
    },
    labelTop: {
        color: commonStyle.textWhiteColor,
        marginTop: 2,
        textAlign:'center'
    },
    switchSection: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    country: {
        // marginRight: 2,
        color: '#828B9F'
    },
    switch: {
        // backgroundColor: '#ccc',
        // padding: 5,
        // borderRadius: 5,
        // color: '#828B9F'
    },
    iconText: {
        flexDirection: 'row',
        alignItems: 'center',
        // marginBottom: 10,
        color: '#828B9F',
    },
    icon: {
        // backgroundColor: '#ccc',
        // width: 30,
        // height: 30,
        // marginRight: 10,
        // alignItems: 'center',
        // justifyContent: 'center',
    },
    label: {
        fontSize: 15,
        color: '#828B9F',
        position: 'relative',
        // lineHeight:38
        // color: commonStyle.textWhiteColor
    },
    subText: {
        fontSize: 14,
        marginRight: 10,
        color: '#828B9F'
    },
    goArrow: {
        fontSize: 20,
        // fontWeight: 'bold',
    },
    iconInner: {
        backgroundColor: '#ccc',
        width: 20,
        height: 20,
        alignItems: 'center',
        justifyContent: 'center',
    },
    image: {
        width: 30,
        height: 30,
        marginHorizontal:'auto',
    },
    image2: {
        width: 35,
        height: 35
    },
    flexColumn: {
        flexDirection: 'column',
        flex: 1
    }
});

export default My;